/*
 * Balão de Inscreva-se
 */

import React from 'react';
import './styles/signUp.scss';

class SignUp extends React.Component{
  render() {
    return (
      <div className="signUp">
        <h2><a href="#" target="_blank" rel="noopener noreferrer">Inscrições <span>  encerradas</span></a></h2>
      </div>
    );
  }
}

export default SignUp;