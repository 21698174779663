/*
 * Fale Conosco
 */

import React from 'react';
import './styles/contactUs.scss';

class ContactUs extends React.Component{
  constructor(props) {
    super(props);
    
    this.closeButton = this.closeButton.bind(this);
  };

  closeButton() {
    document.body.classList.remove('contactUs');
  }

  componentDidMount(props){
    function resizeContactUs(){
      let contactUs = document.querySelectorAll('#contactUs')[0];

      // Fazendo o cálculo da altura proporcional  ao viewport.
      if( window.innerHeight > 1080 ) {
        contactUs.style.top = 'auto';
        contactUs.style.height = '1080px';
        contactUs.style.width = ( ( 1080 * 504 ) / 690 ) + 'px';
      } else {
        contactUs.style.top = '0';
        contactUs.style.height = 'auto';
        contactUs.style.width = ( ( window.innerHeight * 504 ) / 690 ) + 'px';
      }
    }

    resizeContactUs();
    window.addEventListener('resize', resizeContactUs);
  }

  render() {
    return (
      <div id="contactUs">
        <div>
          <button type="button" className="close" title="Fechar" onClick={(e) => this.closeButton()}>Fechar</button>
          <h2>Fale conosco</h2>

          <div>
            <p>Mais informações podem ser obtidas pelo e-mail <a href="mailto:premioana@ana.gov.br">premioana@ana.gov.br</a> ou pelo telefone (61) 2109-5638 (das 9h às 12h e das 14h às 17h).</p>

            <button type="button" onClick={(e) => this.closeButton()} className="btn">Fechar</button>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;