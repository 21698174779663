/*
 * Seta para indicar rolagem
 */

import React from 'react';
import jQuery from 'jquery';
import './styles/arrowBottom.scss';

class ArrowBottom extends React.Component{
  componentDidMount() {
    jQuery(window).scroll(() =>{
      if( jQuery('span.arrowBottom').length ){
        if( jQuery(window).scrollTop() >= ( jQuery(document).innerHeight() - ( jQuery(window).innerHeight() + ( jQuery(window).innerHeight() / 1.5 ) ) ) )
          jQuery('span.arrowBottom').fadeOut();
        else
          jQuery('span.arrowBottom').fadeIn();
      }
    });
  }

  render() {
    return (
      <span className="arrowBottom" ref={arrowBottom => this.arrowBottom = arrowBottom} />
    );
  }
}

export default ArrowBottom;