/*
 * Calendário
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './styles/schedule.scss';

class Schedule extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                steps: [{
                        title: 'Inscrições',
                        subtitle: '',
                        desc: 'Até 15 de agosto'
                    },
                    {
                        title: 'Avaliação',
                        subtitle: '',
                        desc: 'Até 24 de novembro'
                    },
                    {
                        title: 'Divulgação dos finalistas',
                        subtitle: '',
                        desc: '2 de dezembro'
                    },
                    {
                        title: 'Premiação',
                        subtitle: '',
                        desc: 'Março de 2021'
                    }
                ]
            };
        };

        render() {
            return ( <div id = "schedule"
                className = "clear" >
                <ul className = "clear" > {
                    this.state.steps.map((el, index) => < li key = { index }
                        style = {
                            { width: (100 / this.state.steps.length) + '%', zIndex: (this.state.steps.length - index) } }
                        className = {
                            (index <= (this.props.step - -1) ? 'active' : '') } >
                        <h4 > { el.title } </h4> <span > </span> <h5 className = { el.subtitle === '' ? 'hide' : '' } > { el.subtitle } </h5> <p> { el.desc } </p> </li>)
                    } </ul> </div>
                );
            }
        }

        Schedule.propTypes = {
            step: PropTypes.number.isRequired
        }

        export default withRouter(Schedule);