/*
 * Prêmio ANA 2023
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Schedule from '../../components/Schedule';
import TwitterEmbed from '../../components/TwitterEmbed';
import ANAAwardSubNav from '../../components/ANAAwardSubNav';
import Footer from '../../components/Footer';
import './styles/anaAward.scss';
import anaAwardPng from '../../assets/img/anaAward-head.png';
import anaAwardWebp from '../../assets/img/anaAward-head.webp';
import anaAwardMoboPng from '../../assets/img/anaAward-head-mobo.png';


class ANAAward extends React.Component{
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    this.state = {
      categories: [
        {
          title: 'Governo',
          subtitle: '',
          hover: 'Órgãos e entidades da Administração Pública Federal, Estadual, Distrital ou Municipal, no âmbito dos Poderes Executivo, Legislativo ou Judiciário, consórcios intermunicipais, assim como o Ministério Público, as Defensorias Públicas e os Tribunais de Contas.'
        },
        {
          title: 'Empresas de Micro ou de Pequeno Porte',
          subtitle: '',
          hover: 'Empresas públicas ou privadas classificadas como micro ou pequenas, ou seja, com faturamento anual igual ou inferior a R$ 4,8 milhões, conforme Lei Complementar n° 123, de 14 de dezembro de 2006. Enquadram-se também nessa categoria empresas classificadas como startup.'
        },
        {
          title: 'Empresas de Médio e de Grande Porte',
          subtitle: '',
          hover: 'Empresas públicas ou privadas com faturamento anual superior a R$ 4,8 milhões.'
        },
        {
          title: 'Organizações Civis',
          subtitle: '',
          hover: 'Associações não governamentais sem fins lucrativos (também chamadas de organizações da sociedade civil), com atuação em áreas relacionadas aos objetivos da premiação.'
        },
        {
          title: 'Educação – Ensino Fundamental, Médio e Educação não Formal',
          subtitle: '',
          hover: 'Estudantes e profissionais de instituições de ensino fundamental e médio, além de instituições de ensino não formal, como museus, centros culturais, de ciências, de educação ambiental, bibliotecas, jardins botânicos, zoológicos e planetários.'
        },
        {
          title: 'Educação - Ensino Superior e Pesquisa',
          subtitle: '',
          hover: 'Estudantes e profissionais voltados à produção científica e tecnológica de instituições de ensino superior (como graduação, mestrado, doutorado e pós-graduação) e/ou de pesquisa, com atuação nas áreas de recursos hídricos e de saneamento básico.'
        },
        {
          title: 'Comunicação – Mídia Audiovisual',
          subtitle: '',
          hover: 'Jornalistas e produtores de conteúdos de emissoras de TV e de canais audiovisuais veiculados via internet. Poderão inscrever nesta categoria programas, filmes e séries de reportagens relacionadas a qualquer tema que tenha pertinência com os objetivos do Prêmio ANA, com veiculação a partir de 15 de agosto de 2020 até o encerramento das inscrições desta edição da premiação.'
        },
        {
          title: 'Comunicação – Mídia Impressa ou Sonora',
          subtitle: '',
          hover: 'Jornalistas e produtores de conteúdos em texto ou em áudio, veiculados em plataformas analógicas ou digitais, como jornais, revistas, sites, rádios e canais de podcast. Os materiais devem se referir a qualquer tema relacionado aos objetivos do Prêmio ANA com veiculação comprovadamente realizada a partir de 15 de agosto de 2020 até o encerramento das inscrições desta edição da premiação.'
        },
        {
          title: 'Organismos de Bacias',
          subtitle: '',
          hover: 'Órgãos e entidades gestores de recursos hídricos, conselhos, associações e consórcios de municípios, associações de usuários, comitês de bacia, agências de água e delegatárias das funções de agências de bacia e outras organizações afins, estabelecidas em âmbito de bacias hidrográficas.'
        },
        {
          title: 'Entidades Reguladoras Infranacionais do Setor de Saneamento Básico',
          subtitle: '',
          hover: 'Instituições públicas reguladoras dos serviços de saneamento básico no Brasil.'
        }        
      ],
      commissionJudges: [
        {
          name: 'Oscar Cordeiro Netto',
          imageLink: './pics/oscar.png',
          desc: 'Diretor da ANA e Presidente da Comissão Julgadora do Prêmio ANA 2020'
        },
        {
          name: 'Marília Marreco',
          imageLink: './pics/marilia.png',
          desc: 'Secretária Executiva da Secretaria de Estado de Meio Ambiente do Governo do Distrito Federal - GDF'
        },
        {
          name: 'Sérgio Carazza',
          imageLink: './pics/sergio.png',
          desc: 'Ouvidor do Serviço Brasileiro de Apoio às Micro e Pequenas Empresas - SEBRAE'
        },
        {
          name: 'Anícia Pio',
          imageLink: './pics/anicia.png',
          desc: 'Gerente do Departamento de Desenvolvimento Sustentável da Federação das Indústrias do Estado de São Paulo – FIESP'
        },
        {
          name: 'Jefferson Oliveira',
          imageLink: './pics/jefferson.png',
          desc: 'Conselheiro do Conselho Nacional de Recursos Hídricos - CNRH e Coordenador Geral do Programa de Pós-Graduação Mestrado Profissional em Rede ProfÁgua'
        },
        {
          name: 'Samuel Campos',
          imageLink: './pics/samuel.png',
          desc: 'Gerente Executivo da Agência UFLA de Inovação em Geotecnologia e Sistemas Inteligentes da Universidade Federal de Lavras.'
        },
        {
          name: 'Rogério Miziara',
          imageLink: './pics/rogerio.png',
          desc: 'Assessor Especial da Diretoria de Desenvolvimento Social da Fundação Banco do Brasil - FBB'
        },
        {
          name: 'Sirlei Batista',
          imageLink: './pics/sirlei.png',
          desc: 'Diretora de Jornalismo da Empresa Brasil de Comunicação – EBC'
        },
        {
          name: 'Lupércio Ziroldo',
          imageLink: './pics/lupercio.png',
          desc: 'Governador Honorário do Conselho Mundial da Água e Presidente da REBOB'
        }
      ]
    };

    this.changeClass = this.changeClass.bind(this);
    this.closeButton = this.closeButton.bind(this);
  }

  changeClass(e){
    e.preventDefault();

    if(document.querySelectorAll('#anaAward #categorias ul li.active').length)
      document.querySelectorAll('#anaAward #categorias ul li.active')[0].classList.remove('active');

    e.target.parentElement.classList.add('active');
  }

  closeButton(){
    if(document.querySelectorAll('#anaAward #categorias ul li.active').length)
      document.querySelectorAll('#anaAward #categorias ul li.active')[0].classList.remove('active');
  }

  componentDidMount(props){
    function resizeANAAward(){
      let divsCategories = document.querySelectorAll('#anaAward #categorias ul li div');

      // Ajustando a largura dos boxes de Categorias proporcionais ao viewport.
      divsCategories.forEach(function(e){
        e.style.width = ( ( document.querySelectorAll('header')[0].clientWidth * 900 ) / 1280 ) + 'px';
      });
    }

    resizeANAAward();
    window.addEventListener('resize', resizeANAAward);
  }

  render() {
    return (
      <main id="anaAward" className="clear">
        <Helmet>
          <meta name="description" content="Prêmio ANA 2020 - Description" />
          <title>O Prêmio</title>
          <body className="anaAward" />
        </Helmet>

        <div className="center">
          <nav className="onlyMobo">
            <ANAAwardSubNav />
          </nav>

          <picture>
            <source media="(min-width: 1153px)" srcSet={( window.Modernizr.webp ? anaAwardWebp : anaAwardPng )} />
            <source media="(max-width: 1152px)" srcSet={anaAwardMoboPng} />
            <img src={( window.Modernizr.webp ? anaAwardWebp : anaAwardPng )} alt=" " />
          </picture>

          <h2>O Prêmio</h2>

          <ul className="two-colums clear" id="objetivos-e-publico-alvo">
            <li>
              <h3>Objetivos</h3>
              <p>O Prêmio ANA busca reconhecer o mérito de iniciativas que se destaquem pela excelência de sua contribuição para a promoção da segurança hídrica, da gestão e do uso sustentável dos recursos hídricos, e para soluções voltadas à melhoria e ampliação dos serviços públicos de saneamento básico, visando ao desenvolvimento sustentável do Brasil.</p>
            </li>
            <li>
              <h3>Público-alvo</h3>
              <p>Poderão concorrer ao Prêmio ANA projetos e iniciativas que estejam alinhados aos objetivos da premiação e inscritos nas categorias contempladas.</p>
            </li>
          </ul>

          <div id="categorias" ref={divCats => this.divCats = divCats}>
            <h3>Categorias</h3>
            <p>Esta edição do Prêmio ANA contempla as seguintes categorias:</p>

            <ul className="clear">
              {
                this.state.categories.map((el, index) => <li key={index}>
                  <h4 onClick={this.changeClass}>{el.title}</h4>
                  <h5>{el.subtitle}</h5>

                  <div>
                    <button type="button" className="onlyMobo close" onClick={this.closeButton}>Fechar</button>
                    <h4 className="onlyMobo">{el.title}</h4>
                    <p>{el.hover}</p>
                  </div>
                </li>)
              }
            </ul>
          </div>

          <ul className="two-colums clear" id="premiacao-e-selecao">
            <li>
              <h3>Premiação</h3>
              <p>Os vencedores do Prêmio ANA 2023 receberão um troféu, em cerimônia de premiação, a ser realizada em Brasília (DF). Os três finalistas de cada categoria receberão um certificado, atestando sua condição de finalista no concurso e participarão da solenidade de premiação. A ANA arcará com as diárias e passagens dos finalistas para traslado dentro do território nacional, para duas pessoas. Os finalistas e vencedores terão o direito de usar o Selo Prêmio ANA: Finalista e o Selo Prêmio ANA: Vencedor, respectivamente, nos materiais de divulgação impressa ou eletrônica das iniciativas premiadas.</p>
            </li>
            <li>
              <h3>Seleção</h3>
              <p>A avaliação do mérito será realizada pela Comissão Julgadora, com apoio da Comissão Organizadora e de servidores do corpo técnico da ANA. A Comissão Julgadora procederá à seleção de três candidaturas finalistas, dentre as quais apontará a única vencedora em cada uma das categorias, segundo análise objetiva dos seguintes critérios:</p>
              <p className="highlight">&bull; Efetividade <br />&bull; Inovação <br />&bull; Impactos social e ambiental <br />&bull; Potencial de difusão <br />&bull; Sustentabilidade* <br />&bull; Adesão social<br />&bull; Aderência aos ODS <br /><em>*não aplicável às categorias de Comunicação</em></p>
            </li>
          </ul>
          { /*
                <div id="comissao-julgadora" className="clear">
                <h3>Comissão Julgadora</h3>
    
                <ul className="clear">
                  {
                    this.state.commissionJudges.map((el, index) => <li key={index} className={( index % 3 === 0 ? 'clearBoth' : '' )}>
                      <span className="image">
                        <img src={el.imageLink} alt={el.name} />
                      </span>
    
                      <h4>{el.name}</h4>
    
                      <p>{el.desc}</p>
                    </li>)
                  }
                </ul>
                </div>

          

          <div id="cronograma" className="clear">
            <h3>Cronograma</h3>

            <Schedule step={1} />
          </div>
                          */
        }
        </div>
        {
          /*
        <TwitterEmbed limit="2" />
        */
        }
        <Footer />
      </main>
    );
  }
}

export default withRouter(ANAAward);