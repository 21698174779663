/*
 * Home do site
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SlideHome from '../../components/SlideHome';
import LearnMore from '../../components/LearnMore';
import ArrowHome from '../../components/ArrowHome';
import Footer from '../../components/Footer';
import './styles/home.scss';
/*import homeHeadPng from '../../assets/img/hotsite-area-reservada-prorrogado.png';
import homeHeadWebp from '../../assets/img/hotsite-area-reservada-prorrogado.webp';
import homeHeadMoboPng from '../../assets/img/hotsite-area-reservada-prorrogado.png';*/
import homeHeadPng from '../../assets/img/hotsite-area-reservada.png';
import homeHeadWebp from '../../assets/img/hotsite-area-reservada.webp';
import homeHeadMoboPng from '../../assets/img/hotsite-area-reservada.png';
import homeAnaAwardsPng from '../../assets/img/home-ana-awards.png';
import homeAnaAwardsWebp from '../../assets/img/home-ana-awards.webp';
import homeAnaAwardsFuturePng from '../../assets/img/home-ana-awards-future.png';
import homeAnaAwardsFutureWebp from '../../assets/img/home-ana-awards-future.webp';
import { Link } from 'react-router-dom'


class Home extends React.Component{
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    this.state = {
      itemsSlideHome: [
        {
            title: 'Prêmio ANA 2023 recebe 618 inscrições de todo o Brasil',
            text: '',
            internal: false,
            target: 'noticias/agencia-prorroga-inscricoes-premio-ana-2023-ate-abril'
          }
         /* ,
        {
          title: 'Agência revela boas práticas vencedoras do Prêmio ANA 2020',
          text: '',
          internal: false,
          target: '/2023/noticias/agencia-revela-boas-praticas-vencedoras-do-premio-ana-2020'
        }
        */
      ],
      relevantNumbers: [
        {
          number: '7',
          text: 'edições'
        },
        {
          number: '2.960',
          text: 'inscritos'
        },
        {
          number: '48',
          text: 'ganhadores'
        }
      ]
    };
  }

  componentDidMount(props){
    //document.getElementById('sidebar').style.height = document.body.clientHeight + 'px';
  }

  render() {
    return (
      <main id="home" className="clear">
        <Helmet>
          <body className="home" />
        </Helmet>

        <picture>
          <source media="(min-width: 1153px)" srcSet={( window.Modernizr.webp ? homeHeadWebp : homeHeadPng )} />
          <source media="(max-width: 1152px)" srcSet={homeHeadMoboPng} />
          <img src={( window.Modernizr.webp ? homeHeadWebp : homeHeadPng )} alt=" " />
        </picture>

        <div className="center">
          <SlideHome items={this.state.itemsSlideHome} />

          <div className="anaAwards clear">
            <img src={window.Modernizr.webp ? homeAnaAwardsWebp : homeAnaAwardsPng} alt=" " />

            <h2>Prêmio ANA 2023</h2>
            <p>Conheça a premiação que reconhece o mérito das iniciativas que se destacaram pela excelência de sua contribuição para a segurança hídrica do Brasil.</p>
            <Link to='/premio-ana-2023' title={!!this.props.text ? this.props.text : 'Saiba Mais'} className="learnMore">{!!this.props.text ? this.props.text : 'Saiba Mais'}</Link>
          </div>

          {/* <div className="anaAwardsFuture clear">
            <img src={window.Modernizr.webp ? homeAnaAwardsFutureWebp : homeAnaAwardsFuturePng} alt=" " />

            <h2>ANA,<br /> inovação e o futuro<br /> das águas no Brasil</h2>
            <p>Esta edição especial do Prêmio ANA celebra os 20 anos da Agência e realiza, além da premiação tradicional, hackathons e outras atividades durante o ano, a fim de encontrar as principais ideias e projetos com o maior potencial de impactar a segurança hídrica do Brasil ao longo desses anos.</p>
            <LearnMore internal={true} target="/premio-ana-futuro-da-agua" text="Fique por dento" />
          </div> */}

          <div className="relevantNumbers clear">
            <h2>Há 16 anos, o Prêmio ANA reconhece  <br />as melhores práticas no Brasil</h2>

            <div>
              <ul className="clear numbers">
                {
                  this.state.relevantNumbers.map((el, index) => <li key={index}>
                    <h3>{el.number}</h3>
                    <p>{el.text}</p>
                  </li>)
                }
              </ul>
            </div>

            {/* <LearnMore internal={false} target="http://premio.ana.gov.br/projetos/" text="Explore nosso banco de projetos" /> */}
          </div>
        </div>
        <ArrowHome />
        <Footer />
      </main>
      
    );
  }
}

export default withRouter(Home);
