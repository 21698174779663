/*
 * Página de notícias
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import data from "../../utils/news.json";
import TwitterEmbed from '../../components/TwitterEmbed';
import './styles/news.scss';
import newsMoboPng from '../../assets/img/news-mobo.png';
import newsPng from '../../assets/img/news.png';
import newsWebp from '../../assets/img/news.webp';

class News extends React.Component{
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main id="news" className="clear list">
        <Helmet>
          <meta name="description" content="Prêmio ANA 2023 - Description" />
          <title>Notícias</title>
          <body className="news" />
        </Helmet>

        <div className="center">
          <picture>
            <source media="(min-width: 1153px)" srcSet={( window.Modernizr.webp ? newsWebp : newsPng )} />
            <source media="(max-width: 1152px)" srcSet={newsMoboPng} />
            <img src={( window.Modernizr.webp ? newsWebp : newsPng )} alt=" " />
          </picture>

          <h2>Notícias</h2>

          <h3>Últimas notícias</h3>

          <ul className="list clear">
            {
              data.news.map((el, index) => <li className="clear" key={index}>
                <Link to={'/noticias/' + el.id } className="clear">
                  <span className="image">
                    <img src={el['image-list']} alt={el.title} />
                  </span>
                  <h4>{el.title}</h4>
                  <p>{el.summary}</p>
                  <p className="date">{el.date}</p>
                </Link>
              </li>)
            }
          </ul>
        </div>

        {/*<TwitterEmbed limit="2" />*/}
      </main>
    );
  }
}

export default withRouter(News);