/*
 * Sidebar do site
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ANAAwardSubNav from '../ANAAwardSubNav';
import './styles/sidebar.scss';
import logoAnaHomePng from '../../assets/img/logo-ana-sidebar-home.png';
import logoAnaHomeWebp from '../../assets/img/logo-ana-sidebar-home.webp';
import logoAnaPng from '../../assets/img/logo-ana.png';
import logoAnaWebp from '../../assets/img/logo-ana.webp';

class Sidebar extends React.Component{
  componentDidMount(props){
    function resizeSidebar(){
      let sidebar = document.querySelectorAll('#sidebar')[0];

      // Fazendo o cálculo do padding-top proporcional ao viewport.
      sidebar.style.paddingTop = ( ( sidebar.clientWidth * 241 ) / 324 ) + 'px';

      // Ajustando o margin-bottom do menu proporcional ao viewport.
      document.querySelectorAll('#sidebar nav').forEach(function(e){
        e.style.marginBottom = ( ( sidebar.clientWidth * 170 ) / 324 ) + 'px';
      });

      // Ajustando a fonte dos itens do menu proporcional ao viewport.
      document.querySelectorAll('#sidebar nav a').forEach(function(e){
        e.style.fontSize = ( ( sidebar.clientWidth * 14 ) / 324 ) + 'px';
      });

      // Ajustando a fonte dos itens do menu proporcional ao viewport.
      document.querySelectorAll('#sidebar h2').forEach(function(e){
        e.style.fontSize = ( ( sidebar.clientWidth * 12 ) / 324 ) + 'px';
        e.style.marginBottom = ( ( sidebar.clientWidth * 14 ) / 324 ) + 'px';
      });
    }

    resizeSidebar();
    window.addEventListener('resize', resizeSidebar);
  }

  render() {
    /*let _sidebarInterv = null,
        _sidebarCount = 0;
    
    _sidebarInterv = window.setInterval(() => {
      _sidebarCount++;

      document.querySelectorAll('#sidebar')[0].style.height = document.body.clientHeight + 'px';

      if( _sidebarCount >= 12 )
        window.clearInterval(_sidebarInterv);
    }, 100);*/

    return (
      <section id="sidebar">
        <nav className="clear">
          <ul className="clear">
            <li className={this.props.location.pathname === '/' ? 'active' : ''}>
              <Link to="/" title="Início">Início</Link>
            </li>
            <li className={'sub' + ( this.props.location.pathname === '/premio-ana-2023' ? ' active' : '' )}>
              <Link to="/premio-ana-2023" title="Prêmio ANA 2023">Prêmio ANA 2023</Link>
              <ANAAwardSubNav />
            </li>
            {/* <li className={'exp' + ( this.props.location.pathname === '/premio-ana-futuro-da-agua' ? ' active' : '' )}>
              <Link to="/premio-ana-futuro-da-agua" title="Prêmio ANA: Futuro da Água">Prêmio ANA: Futuro da Água</Link>
            </li> */}
            <li className={!!this.props.location.pathname.match(/\/noticias/g) ? 'active' : ''}>
               <Link to="/noticias" title="Notícias">Notícias</Link>
              {/*<a href="https://www.gov.br/ana/pt-br/@@search?Subject%3Alist=pr%C3%AAmio%20ana" target="_blank" rel="noopener noreferrer" title={'Notícias'}>Notícias</a>*/}
            </li>
          </ul>
        </nav>
         {/*       
        <div>
          <h2>Realização:</h2>
          <a href="https://www.gov.br/ana" target="_blank" rel="noopener noreferrer" title={'ANA - Agência Nacional de Águas'}>
              <img src={window.Modernizr.webp ? logoAnaWebp : logoAnaPng} alt="ANA - Agência Nacional de Águas" />
          </a>
        </div>
        */}
      </section>
    );
  }
}

export default withRouter(Sidebar);