/*
 * Botão Saiba Mais
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './styles/learnMore.scss';

class LearnMore extends React.Component{
  render() {
    if(this.props.internal)
      return (<Link to='/noticias/agencia-revela-boas-praticas-vencedoras-do-premio-ana-2020' title={!!this.props.text ? this.props.text : 'Saiba Mais'} className="learnMore">{!!this.props.text ? this.props.text : 'Saiba Mais'}</Link>);
    else
      return (<a href={this.props.target} className="learnMore" title={!!this.props.text ? this.props.text : 'Saiba Mais'} target="_blank" rel="noopener noreferrer">{!!this.props.text ? this.props.text : 'Saiba Mais'}</a>);
  }
}

LearnMore.propTypes = {
  target: PropTypes.string.isRequired,
  internal: PropTypes.bool.isRequired,
  text: PropTypes.string
}

export default withRouter(LearnMore);