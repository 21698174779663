import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './assets/sass/general.scss';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import SignUp from './components/SignUp';
import ANAAward from './containers/ANAAward';
import ANAAwardFuture from './containers/ANAAwardFuture';
import Home from './containers/Home';
import News from './containers/News';
import NewsDetail from './containers/News/Detail';
import Page404 from './containers/Page404';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <BrowserRouter  basename={'/2023/'} >
      <Helmet titleTemplate="%s - Prêmio ANA 2023" defaultTitle="Prêmio ANA 2023">
        <meta name="description" content="Prêmio ANA 2023 - Description" />
      </Helmet>
      <Header />
      <Sidebar />
      <ContactUs />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/premio-ana-2023" component={ANAAward} />
        <Route path="/premio-ana-futuro-da-agua" component={ANAAwardFuture} />
        <Route path="/noticias" component={News} exact />
        <Route path="/noticias/:id" component={NewsDetail} />
        <Route path="" component={Page404} />
      </Switch>
      <SignUp />
      {
        /*<Footer />*/
      }
    </BrowserRouter>
, document.getElementById('premio-ana'));

serviceWorker.unregister();