/*
 * Embed do Twitter
 */

import React from 'react';
import PropTypes from 'prop-types';
import LearnMore from '../LearnMore';
import './styles/twitterEmbed.scss';

class TwitterEmbed extends React.Component{
  componentDidMount(){
    const anchor = document.createElement('a');
    anchor.setAttribute('class', 'twitter-timeline');
    anchor.setAttribute('data-theme', 'transparent');
    anchor.setAttribute('data-tweet-limit', this.props.limit);
    anchor.setAttribute('data-chrome', 'noheader nofooter noborders');
    anchor.setAttribute('href', 'https://twitter.com/ANAgovbr');
    document.querySelectorAll('#twitterEmbed .tweets')[0].appendChild(anchor);

    const script = document.createElement('script');
    script.setAttribute('src', 'https://platform.twitter.com/widgets.js');
    document.querySelectorAll('#twitterEmbed .tweets')[0].appendChild(script);
  };
  
  render() {
    return (
      <div id="twitterEmbed">
        <div className="center">
          <h3>Saiu no Twitter</h3>

          <div className="tweets"></div>

          <LearnMore internal={false} target="https://twitter.com/ANAgovbr" text="Veja todos" />
        </div>
      </div>
    );
  }
}

TwitterEmbed.propTypes = {
  limit: PropTypes.string.isRequired
}

export default TwitterEmbed;