/*
 * Submenu da página O Prêmio
 */

import React from 'react';
import jQuery from 'jquery';
import { withRouter } from 'react-router-dom';
import './styles/anaAwardSubNav.scss';

class ANAAwardSubNav extends React.Component{
  constructor(props) {
    super(props);

    this.scrollTo = this.scrollTo.bind(this);
  }

  scrollTo(e){
    e.preventDefault();
    jQuery( 'html, body' ).animate({ scrollTop: ( jQuery('#' + jQuery(e.target).parent().attr('class').replace(/ active/g, '')).offset().top - document.querySelectorAll('header')[0].clientHeight ) + 5 });
  }

  componentDidMount(props){
    window.addEventListener('scroll', function(a){
      let _scrollY = window.scrollY + document.querySelectorAll('header')[0].clientHeight;

      if(!!document.body.classList.contains('anaAward'))
      {
        if(document.querySelectorAll('#objetivos-e-publico-alvo').length) {
          if( _scrollY >= (document.querySelectorAll('#objetivos-e-publico-alvo')[0].offsetTop) && _scrollY < ( ( document.querySelectorAll('#objetivos-e-publico-alvo')[0].offsetTop + document.querySelectorAll('#objetivos-e-publico-alvo')[0].clientHeight ) ) )
            jQuery( 'nav li.objetivos-e-publico-alvo' ).addClass( 'active' );
          else
            jQuery( 'nav li.objetivos-e-publico-alvo' ).removeClass( 'active' );
        }

        if(document.querySelectorAll('#categorias').length) {
          if( _scrollY >= (document.querySelectorAll('#categorias')[0].offsetTop) && _scrollY < ( ( document.querySelectorAll('#categorias')[0].offsetTop + document.querySelectorAll('#categorias')[0].clientHeight )) )
            jQuery( 'nav li.categorias' ).addClass( 'active' );
          else
            jQuery( 'nav li.categorias' ).removeClass( 'active' );
        }

        if(document.querySelectorAll('#premiacao-e-selecao').length) {
          if( _scrollY >= (document.querySelectorAll('#premiacao-e-selecao')[0].offsetTop) && _scrollY < ( ( document.querySelectorAll('#premiacao-e-selecao')[0].offsetTop + document.querySelectorAll('#premiacao-e-selecao')[0].clientHeight ) ) )
            jQuery( 'nav li.premiacao-e-selecao' ).addClass( 'active' );
          else
            jQuery( 'nav li.premiacao-e-selecao' ).removeClass( 'active' );
        }

        if(document.querySelectorAll('#comissao-julgadora').length) {
          if( _scrollY >= (document.querySelectorAll('#comissao-julgadora')[0].offsetTop) && _scrollY < ( ( document.querySelectorAll('#comissao-julgadora')[0].offsetTop + document.querySelectorAll('#comissao-julgadora')[0].clientHeight ) ) )
            jQuery( 'nav li.comissao-julgadora' ).addClass( 'active' );
          else
            jQuery( 'nav li.comissao-julgadora' ).removeClass( 'active' );
        }

        if(document.querySelectorAll('#cronograma').length) {
          if( _scrollY >= (document.querySelectorAll('#cronograma')[0].offsetTop) && _scrollY < ( ( document.querySelectorAll('#cronograma')[0].offsetTop + document.querySelectorAll('#cronograma')[0].clientHeight ) ) )
            jQuery( 'nav li.cronograma' ).addClass( 'active' );
          else
            jQuery( 'nav li.cronograma' ).removeClass( 'active' );
        }
      }
    });
  }

  render() {
    return (
      <ul className="clear anaAwardSubNav">
        <li className="objetivos-e-publico-alvo">
          <a href="./2023/premio-ana-2023#objetivos-e-publico-alvo" title="Objetivos e Público" onClick={this.scrollTo}>Objetivos e Público</a>
        </li>
        <li className="categorias">
          <a href="./2023/premio-ana-2023#categorias" title="Categorias" onClick={this.scrollTo}>Categorias</a>
        </li>
        <li className="premiacao-e-selecao">
          <a href="./2023/premio-ana-2023#premiacao-e-selecao" title="Premiação e Seleção" onClick={this.scrollTo}>Premiação e Seleção</a>
        </li>
        {
          /* 
        <li className="comissao-julgadora">
          <a href="./premio-ana-2023#comissao-julgadora" title="Comissão Julgadora" onClick={this.scrollTo}>Comissão Julgadora</a>
        </li>
        <li className="cronograma">
          <a href="./premio-ana-2023#cronograma" title="Cronograma" onClick={this.scrollTo}>Cronograma</a>
        </li>*/
        }

      </ul>
    );
  }
}

export default withRouter(ANAAwardSubNav);
