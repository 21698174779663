/*
 * Página não encontrada - 404
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import './styles/page404.scss';

class Page404 extends React.Component{
  render() {
    return (
      <div className="page404">
        <Helmet>
          <title>Page404 Page</title>
          <meta name="description" content="Prêmio ANA 2023 - Description" />
        </Helmet>
        
      </div>
    );
  }
}

export default Page404;