/*
 * Footer do site
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import './styles/footer.scss';
import footerSeloPng from '../../assets/img/footer-selo.png';
import footerSeloWebp from '../../assets/img/footer-selo.webp';
import footerApoio from '../../assets/img/Footer-Apoio.png';

class Footer extends React.Component{
  render() {


    if( !( !!this.props.match.isExact && this.props.match.path === '/2023/') ){

        return (
            <footer>
             <img src={footerApoio} alt="Apoio e Realização Prêmio ANA 2023" />
             <br /><br /><br />
            </footer>
            
            );
        
    }
        
    /*    
    if( !( !!this.props.match.isExact && this.props.match.path === '/2023/') )
      return (
        <footer>
          <img src={window.Modernizr.webp ? footerSeloWebp : footerSeloPng} alt="20 anos - #AÁGUAÉUMASÓ :: ANA - Agência Nacional de Águas" />
        </footer>
      );
    else
      return (
        <footer className="onlyMobo">
          <img src={window.Modernizr.webp ? footerSeloWebp : footerSeloPng} alt="20 anos - #AÁGUAÉUMASÓ :: ANA - Agência Nacional de Águas" />
        </footer>
      );
    */
     
  }
}

export default withRouter(Footer);