/*
 * Página de notícias - Interna
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import data from "../../utils/news.json";
import LearnMore from '../../components/LearnMore';
import TwitterEmbed from '../../components/TwitterEmbed';
import Page404 from '../Page404';
import './styles/news.scss';
import { Link } from 'react-router-dom';


class Detail extends React.Component{
  constructor(props) {
    super(props);
    
    window.scrollTo(0, 0);

    this.state = {
      new: data.news.filter(function(notice){return notice.id === this.props.match.params.id;}.bind(this))[0]
    }
  }

  render() {
    if(!!!this.state.new)
      return (
        <Page404 />
      );
    else
      return (
        <main id="news" className="clear detail">
          <Helmet>
            <meta name="description" content={this.state.new.summary} />
            <title>{this.state.new.title}</title>
            <body className="news detail" />
          </Helmet>

          <div className="center head">
          <Link to='/noticias' title={!!this.props.text ? this.props.text : 'Saiba Mais'} className="learnMore">{!!this.props.text ? this.props.text : 'Voltar'}</Link>
            <h2>Notícias</h2>
          </div>

          <div className="imageDetail">
            <div>
              <h3>{this.state.new.title}</h3>
              <p>{this.state.new.date}</p>
            </div>
            <img src={this.state.new['image-title']} alt={this.state.new.title} />
          </div>

          <div className="center">
            <div className="content" dangerouslySetInnerHTML={{__html: this.state.new.content}} />
          </div>

          {/*<TwitterEmbed limit="2" />*/}
        </main>
      );
  }
}

export default withRouter(Detail);