/*
 * Header do site
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './styles/header.scss';
import logoPng from '../../assets/img/premio-ana-2023.png';
import logoWebp from '../../assets/img/premio-ana-2023.webp';

const { detect } = require('detect-browser');



class Header extends React.Component{
  constructor(props) {
    super(props);
    
    this.changeClass = this.changeClass.bind(this);
  };

  changeClass(term) {
    if(term === 'prevEditions') {
      if(document.body.classList.contains('contactUs'))
        document.body.classList.remove('contactUs');
    }else if(term === 'contactUs') {
      if(document.body.classList.contains('prevEditions'))
        document.body.classList.remove('prevEditions');

      if(document.body.classList.contains('menuMobo'))
        document.body.classList.remove('menuMobo');
    }else if(term === 'menuMobo') {
      if(window.innerWidth <= 1152) {
        if(document.body.classList.contains('prevEditions'))
          document.body.classList.remove('prevEditions');

        if(document.body.classList.contains('contactUs'))
          document.body.classList.remove('contactUs');

        document.body.classList.toggle(term);
      }

      return;
    }

    document.body.classList.toggle(term);
  }

  componentDidMount(props){
    const browser = detect();

    function resizeHeader(){
      let header = document.querySelectorAll('header')[0];

      if(window.innerWidth > 1152) {
        // Fazendo o cálculo da altura proporcional  ao viewport.
        header.style.height = ( ( header.clientWidth * 151 ) / 1280 ) + 'px';

        // Ajustando a fonte dos itens do menu proporcional ao viewport.
        document.querySelectorAll('header nav a, header nav button').forEach(function(e){
          e.style.fontSize = ( ( header.clientWidth * 14 ) / 1280 ) + 'px'
        });
      } else {
        // Fazendo o cálculo da altura proporcional  ao viewport.
        header.style.height = ( ( header.clientWidth * 79 ) / 360 ) + 'px';
      }

      if(window.innerWidth <= 1152)
        window.VIEWPORT_ver = 'mobile';
      else
        window.VIEWPORT_ver = 'desktop';
    }

    resizeHeader();
    window.addEventListener('resize', resizeHeader);

    document.documentElement.classList.add(browser.name);
  }

  render() {
    return (
      <header>
        <h1>
          <Link to="/" title="Prêmio ANA 2023"><img src={window.Modernizr.webp ? logoWebp : logoPng} alt="Prêmio ANA 2023" /></Link>
        </h1>

        <button type="button" className="onlyMobo menuMobo" onClick={(e) => this.changeClass('menuMobo', e)}>menuMobo</button>

        <span></span>
        <a href="https://www.gov.br/ana" title="Visitar o portal da Agência Nacional de Águas">www.gov.br/ana</a>

        <ul className="socialMedia">
          <li>
            <a href="https://www.youtube.com/channel/UCLLjIf0AjdwQAtp3hDmrhZQ" title="YouTube" className="yt" target="_blank" rel="noopener noreferrer">YouTube</a>
          </li>
          <li>
            <a href="https://instagram.com/anagovbr" title="Instagram" className="ig" target="_blank" rel="noopener noreferrer">Instagram</a>
          </li>
          <li>
            <a href="https://twitter.com/anagovbr" title="Twitter" className="tw" target="_blank" rel="noopener noreferrer">Twitter</a>
          </li>
          <li>
            <a href="https://facebook.com/anagovbr" title="Facebook" className="fb" target="_blank" rel="noopener noreferrer">Facebook</a>
          </li>
          <li>
            <a href="https://br.linkedin.com/company/anagovbr" title="LinkedIn" className="in" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </li>
          <li>
            <a href="https://api.whatsapp.com/send?text=http%3A%2F%2Fpremio.ana.gov.br/" title="WhatsApp" className="wp" target="_blank" rel="noopener noreferrer">WhatsApp</a>
          </li>
        </ul>

        <nav className="clear onlyMobo">
          <ul className="clear">
            <li className={this.props.location.pathname === '/' ? 'active' : ''}>
              <Link to="/" title="Início">Início</Link>
            </li>
            <li className={'sub' + ( this.props.location.pathname === '/premio-ana-2023' ? ' active' : '' )}>
              <Link to="/premio-ana-2023" title="Prêmio ANA 2023">Prêmio ANA 2023</Link>
            </li>
            {/* <li className={'exp' + ( this.props.location.pathname === '/premio-ana-futuro-da-agua' ? ' active' : '' )}>
              <Link to="/premio-ana-futuro-da-agua" title="Prêmio ANA: Futuro da Água">Prêmio ANA: Futuro da Água</Link>
            </li> */}
            <li className={!!this.props.location.pathname.match(/\/noticias/g) ? 'active' : ''}>
              {
              <Link to="/noticias" title="Notícias">Notícias</Link>
              /*<a href="https://www.gov.br/ana/pt-br/@@search?Subject%3Alist=pr%C3%AAmio%20ana" target="_blank" rel="noopener noreferrer" title={'Notícias'}>Notícias</a>*/
              }
            </li>
          </ul>
        </nav>

        <nav className="clear top">
          <ul className="clear">
            <li>
              <a href="http://premioinscricao.ana.gov.br/" title="Inscrições" target="_blank" rel="noopener noreferrer">Inscrições</a>
            </li>
            <li className="prevEditions">
              <button type="button" title="Edições anteriores" onClick={(e) => this.changeClass('prevEditions', e)}>Edições Anteriores</button>

              <div>
                <ul>
                  <li>
                    <a href="https://premio.ana.gov.br/2020/premio-ana-2020" title="2020" target="_blank" rel="noopener noreferrer">2020</a>
                  </li>
                  <li>
                    <a href="http://premio.ana.gov.br/Edicao/2017/default.aspx" title="2017" target="_blank" rel="noopener noreferrer">2017</a>
                  </li>
                  <li>
                    <a href="http://premio.ana.gov.br/Edicao/2014/default.aspx" title="2014" target="_blank" rel="noopener noreferrer">2014</a>
                  </li>
                  <li>
                    <a href="http://premio.ana.gov.br/Edicao/apresentacao.aspx" title="2012" target="_blank" rel="noopener noreferrer">2012</a>
                  </li>
                  <li>
                    <a href="http://premio.ana.gov.br/edicoes/2010/premio.asp" title="2010" target="_blank" rel="noopener noreferrer">2010</a>
                  </li>
                  <li>
                    <a href="http://premio.ana.gov.br/edicoes/2008/premio.asp" title="2008" target="_blank" rel="noopener noreferrer">2008</a>
                  </li>
                  <li>
                    <a href="http://premio.ana.gov.br/edicoes/2006/apresentacao.htm" title="2006" target="_blank" rel="noopener noreferrer">2006</a>
                  </li>
                </ul>
              </div>
            </li>
            {
              /*
            <li>
              <a href="http://premio.ana.gov.br/projetos/" title="Banco de Projetos" target="_blank" rel="noopener noreferrer">Banco de Projetos</a>
            </li>
            */

            }
            
            <li>
              <a href="./Divulgacao_Premio_ANA.zip" title="Regulamento" target="_blank" rel="noopener noreferrer">Kit de divulgação</a>
            </li>
            

            <li>
              <a href="https://arquivos.ana.gov.br/premioana/Regulamento_PremioANA_2023.pdf?v20230322" title="Regulamento" target="_blank" rel="noopener noreferrer">Regulamento</a>
            </li>
            <li className="contactUs">
              <button type="button" title="Fale Conosco" onClick={(e) => this.changeClass('contactUs', e)}>Fale Conosco</button>
            </li>
          </ul>
        </nav>

        <a href="https://www.gov.br/ana" title="Visitar o portal da Agência Nacional de Águas" className="clear onlyMobo">www.gov.br/ana</a>
      </header>
    );
  }
}

export default withRouter(Header);