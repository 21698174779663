/*
 * Prêmio ANA e o Futuro da Água
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TwitterEmbed from '../../components/TwitterEmbed';
import './styles/anaAwardFuture.scss';
import anaAwardFutureEventPng from '../../assets/img/anaAwardFuture-event.png';
import anaAwardFutureEventWebp from '../../assets/img/anaAwardFuture-event.webp';
import anaAwardFuturePng from '../../assets/img/anaAwardFuture-head.png';
import anaAwardFutureWebp from '../../assets/img/anaAwardFuture-head.webp';
import anaAwardFutureMoboPng from '../../assets/img/anaAwardFuture-head-mobo.png';
import campusPartyPng from '../../assets/img/campus-party.png';
import campusPartyWebp from '../../assets/img/campus-party.webp';
import rioCreativeConferencePng from '../../assets/img/rio-creative-conference.png';
import rioCreativeConferenceWebp from '../../assets/img/rio-creative-conference.webp';

class ANAAwardFuture extends React.Component{
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);
  }

  componentDidMount(props){

  }

  render() {
    return (
      <main id="anaAwardFuture" className="clear">
        <Helmet>
          <meta name="description" content="Prêmio ANA 2020 - Description" />
          <title>Inovação</title>
          <body className="anaAwardFuture" />
        </Helmet>

        <div className="center">
          <picture>
            <source media="(min-width: 1153px)" srcSet={( window.Modernizr.webp ? anaAwardFutureWebp : anaAwardFuturePng )} />
            <source media="(max-width: 1152px)" srcSet={anaAwardFutureMoboPng} />
            <img src={( window.Modernizr.webp ? anaAwardFutureWebp : anaAwardFuturePng )} alt=" " />
          </picture>

          <h2>Inovação</h2>

          <ul className="firstInfos">
            <li>
              <h3>A ANA nos maiores eventos de inovação e criatividade do Brasil</h3>
              <p>Esta edição especial do Prêmio ANA celebra os 20 anos da Agência e busca reconhecer as principais ideias e projetos com maior potencial de impactar positivamente, e de forma mais significativa, a segurança hídrica do Brasil pelos próximos 20 anos.</p>
              <p>No nosso ano de aniversário, vamos estimular e ouvir os jovens empreendedores e as cabeças inovadoras para nos ajudar na construção do FUTURO DA ÁGUA. Além da premiação tradicional do Prêmio ANA, serão realizadas atividades durante todo o ano a fim de comunicar ao maior número de pessoas os desafios que a Agência tem em sua missão, que é a de cuidar do desenvolvimento do Brasil, da nascente à foz dos nossos rios. Queremos descobrir ideias e projetos inovadores pelo Brasil afora, com potencial em contribuir com respostas para o desafio de garantir segurança hídrica, que é de todos. Afinal, #AÁguaÉumaSó.</p>
              <p>Nesta edição especial do prêmio, a ANA vai participar da Campus Party Brasília e Campus Party Brasil, com maratonas para discutir novas ideias e desenvolver projetos (os chamados hackathons), e também da Rio 2C, com um desafio para empresas emergentes em uma rodada de pitching sessions.</p>
              <p>Acompanhe a seção de <Link to="/noticias" title="notícias deste site">notícias deste site</Link> e fique por dentro das atividades programadas para todo o ano.</p>
            </li>
            <li>
              <a href="https://www.rio2c.com/" target="_blank" rel="noopener noreferrer" title="Rio Creative Conference"><img src={window.Modernizr.webp ? rioCreativeConferenceWebp : rioCreativeConferencePng} alt="Rio Creative Conference" /></a>
              <a href="https://brasil.campus-party.org/" target="_blank" rel="noopener noreferrer" title="Campus Party"><img src={window.Modernizr.webp ? campusPartyWebp : campusPartyPng} alt="Campus Party" className="last" /></a>
              <q>No aniversário de 20 anos da ANA, queremos encontrar as mentes mais criativas, audaciosas e inovadoras que queiram contribuir para o futuro das águas do Brasil.</q>
              <cite>Marcelo Cruz <br />Diretor da ANA e coordenador da Comissão Organizadora do Prêmio ANA</cite>
            </li>
          </ul>
          
          <div className="nextSteps clear">
            <h3>Acompanhe nossas atividades</h3>

            <ul>
              <li>
                <h4><span>29</span>abr</h4>
                <p>Campus Party Brasília – Hackathon Prêmio ANA: Futuro da Água</p>
              </li>
              <li>
                <h4><span>06</span>mai</h4>
                <p>RIO2C  – Pitching Session Prêmio ANA: Futuro da Água</p>
              </li>
              <li>
                <h4><span>07</span>jul</h4>
                <p>Campus Party Brasil – Hackathon Prêmio ANA: Futuro da Água</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="imageEvent">
          <img src={window.Modernizr.webp ? anaAwardFutureEventWebp : anaAwardFutureEventPng} alt=" " />
          {/*<p className="name">Nome do evento lorem mono dolor</p>
          <p>Local do evento</p>*/}
        </div>

        <div className="center">
          <ul className="two-colums clear">
            <li>
              <h3>Hackathons, pitching sessions e muita inspiração</h3>
              <p>O hackathon é uma maratona intensiva de programação na qual times pensam soluções e criam projetos para resolver problemas escolhidos pela organização do evento e seus patrocinadores. Durante esses eventos, os participantes serão estimulados a pensar soluções para um ou mais desafios (definidos pela ANA) à gestão de recursos hídricos para os próximos 20 anos, por meio de exemplos reais ou simulações de ações em uma bacia hidrográficas conceitual.</p>
              <p>As pitching sessions são encontros em que um empreendedor (usualmente representante de uma startup) realiza a defesa oral de uma ideia ou projeto para um grupo de investidores. Nesse cenário, a ANA participa como a instituição que pode avaliar o projeto e conceder seu aval técnico, com o selo “essa ideia promete”, o qual sinaliza para os investidores que o mais importante ator do setor de águas no Brasil reconhece potencial naquela ideia.</p>
            </li>
          </ul>
        </div>

        <TwitterEmbed limit="2" />
      </main>
    );
  }
}

export default withRouter(ANAAwardFuture);