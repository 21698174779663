/*
 * Slide da Home
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LearnMore from '../LearnMore';
import './styles/slideHome.scss';

class SlideHome extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      active: 0
    };

    this.changeSlide = this.changeSlide.bind(this);
  };

  changeSlide(idx){
    this.setState({ active: idx });
  }

  render() {
    return (
      <div className="slideHome clear">
        { ( this.props.items.length > 1 ? this.props.items.map((el, index) => <button onClick={() => this.changeSlide(index)} key={index} type="button" title={el.title} className={'item' + index + (this.state.active === index ? ' active' : '')}>{el.title}</button>) : '' ) }
        <ul className="clear">
          {
            this.props.items.map((el, index) => <li key={index} className={'item' + index + (this.state.active === index ? ' active' : '')}>
              <h2>{el.title}</h2>
              <p>{el.text}</p>
              <LearnMore internal={el.internal} target={el.target} />
            </li>)
          }
        </ul>
      </div>
    );
  }
}

SlideHome.propTypes = {
  items: PropTypes.array.isRequired
}

export default withRouter(SlideHome);